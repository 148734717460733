import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SyncGuard } from '@memberspot/frontend/shared/data-access/common';

import { ClientDashboardManageService } from '../dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardSyncGuard extends SyncGuard {
  constructor(protected service: ClientDashboardManageService) {
    super();
  }

  sync(params: Params) {
    const { schoolId } = params;

    return this.service.sync(schoolId);
  }
}
